import React from "react";
import Amplify from "aws-amplify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Login from "./Pages/Login";
import ResetPassword from "./Pages/ResetPassword";
import SetupPassword from "./Pages/SetupPassword";
import Reset from "./Pages/Reset";
import Success from "./Pages/Success";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/Scss/Main.scss";
import awsconfig from "./aws-exports";
import Logout from "./Pages/Logout";
import LandingPage from "./Pages/LandingPage";
import { AuthProvider } from "./context/AuthContext";

Amplify.configure(awsconfig);

function App() {
    return (
        <AuthProvider>
            <div className="App">
                <Router>
                    <Header />
                    <Routes>
                        <Route exact path="/" element={<LandingPage />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/reset" element={<Reset />} />
                        <Route path="/success" element={<Success />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/setup-password" element={<SetupPassword />} />
                    </Routes>
                </Router>
            </div >
        </AuthProvider>

    );
}

export default App;
