import React from 'react'
import HeroSec from '../components/HeroSec'
import YourPoints from '../components/YourPoints'
import YourRewards from '../components/YourRewards'
import WarnFooter from '../components/WarnFooter'

const LandingPage = () => {
  return (
    <div>    
      <div className='container'>
        <HeroSec />
        <YourPoints />
        <YourRewards />
    </div>
    <WarnFooter />
    </div>

  )
}

export default LandingPage