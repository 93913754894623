import React from "react";
import { useNavigate } from "react-router-dom";
import Raptor from "../Assets/Images/banner section 1.png";
import GroupLogo from "../Assets/Images/Warn group logo.png"
import Wrangler from "../Assets/Images/Main Banner 2.png";

const HeroSec = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="heroSec">
        <div className="heroBox">
          <div className="warnQuadbike">
            <img src={Raptor} alt="Raptor" />
          </div>
          <div className="bgYellowBox">
          <img src={GroupLogo} alt="GroupLogo" />
            <h2>REWARDS PROGRAM FOR</h2>
            <h1>RED LETTER DEALERS</h1>
          </div>
          <div className="warnHilux">
            <img src={Wrangler} alt="Wrangler" />
            <div className="warnText">
              <h2>Get rewarded for The WARN Group product purchases, sales training, business related activity, and more.</h2>
              <button onClick={() => navigate("/login")}>LOGIN</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSec;
