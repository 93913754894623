import React, { useState, useRef } from "react";
import { Button, Container, Form } from "react-bootstrap";
import ReactCodeInput from "react-verification-code-input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import WarnFooter from "../components/WarnFooter";

const LoginSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please Enter your password")
    .min(8, "Your password must be longer than 8 characters.")
    .matches(/^(?=.{8,})/, "A minimum of 8 character")
    .matches(/^(?=.*[0-9])/, "At least 1 number")
    .matches(/^(?=.*[A-Z])/, "At least 1 upper case character")
    .matches(/^(?=.*[a-z])/, "At least 1 lower case character")
    .matches(/^(?=.*[!@#\$%\^&\*])/, "At least 1 special character"),
  passwordConfirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Password and confirmation password do not match."
    ),
});

const Reset = () => {
  const history = useNavigate();
  const password = useRef({});
  const [code, setCode] = useState();
  const [show, setShow] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState(
    "If the email address you entered is present in our system, you will receive an email with a verification code. Please enter the verification code to reset your password."
  );
  const {
    state: { email },
  } = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    reValidateMode: "onChange",
    criteriaMode: "all",
    mode: "onChange",
  });
  password.current = watch("password", "");

  const onSubmit = async (data) => {
    if (data) {
      try {
        setLoader(true);
        await Auth.forgotPasswordSubmit(email, code, data.password);
        history("/success");
        setMessage("");
        seterrorMessage("");
      } catch (error) {
        setLoader(false);
        console.log("Error signing in", error);
        seterrorMessage(
          "The verification code is incorrect. Please try again."
        );
        setShow(true);
      }
      setCode("");
      reset();
    }
  };

  return (
    <div className="Formbg">
      {loader && <Loading />}
      <div className="signUpTopBox"><div className="signUpTopBoxTitle"><h4>Rewards Program For Red Letter Dealers</h4></div></div>
      <Container>
        <div className="formBox">
          <div className="formWrap">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h3>RESET PASSWORD</h3>
              {message && <p className="confirmMessage">{message}</p>}
              <div className="formControl">
                <label>Verification Code</label>
                <ReactCodeInput
                  value={code}
                  onChange={(e) => setCode(e)}
                  className="verificationInput"
                />
              </div>
              <div
                className={"formControl" + (errors.password ? " error" : "")}
              >
                <label>Password*</label>
                <Form.Control type="password" {...register("password")} />
                <div>
                  {errors?.password?.types?.matches && (
                    <p className="errorMessage">Passwords must contain -</p>
                  )}
                  <ul>
                    {typeof errors?.password?.types?.matches === "string" ? (
                      <li className="errorMessage">
                        {errors?.password?.types?.matches}
                      </li>
                    ) : (
                      errors?.password?.types?.matches.length > 0 &&
                      errors?.password?.types?.matches?.map((err, index) => {
                        return (
                          <>
                            <li key={index} className="errorMessage">
                              {err}
                            </li>
                          </>
                        );
                      })
                    )}
                  </ul>
                </div>
              </div>
              <div
                className={
                  "formControl" + (errors.passwordConfirmation ? " error" : "")
                }
              >
                <label>Confirm Password*</label>
                <Form.Control
                  type="password"
                  {...register("passwordConfirmation")}
                />
                {errors.passwordConfirmation && (
                  <p className="errorMessage">
                    {errors.passwordConfirmation.message}
                  </p>
                )}
              </div>
              {successMessage && (
                <p className="confirmMessage">{successMessage}</p>
              )}
              {show && errorMessage && (
                <p className="errorMessage">{errorMessage}</p>
              )}
              <Button
                className="signUpBtn themeBtn"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                RESET
              </Button>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Reset;
