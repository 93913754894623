import React from "react";
import Loader from "../Assets/Svg/loader.gif";

const Loading = () => {
  return (
    <div className="customLoader">
      <img src={Loader} alt="loader" />
    </div>
  );
};

export default Loading;
