import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import yourpoints from "../utils/YourPoints.json";

const YourRewards = () => {
  const navigate = useNavigate();
  const [pointsData, setPointsData] = useState(6);

  return (
    <div id="rewardsSection" className="yourPoints">
      <h2 className="earnPointsText">Earn rewards by redeeming your points.</h2>
      <div className="earnPointsBoxWrap">
        <ul>
          {yourpoints?.slice(0, pointsData).map((item, index) => (
            <li key={index}>
              {console.log('item', item)}
              <img src={require(`../Assets/Images/${item.imgUrl}`)} alt="" />
              <div className="earnPointsContent">
                <div className="titleWrap">
                  <h4>{item.title}</h4>
                  <h6>{item.subTitle}</h6>
                </div>
                <p>{item.pointsDetail}</p>
                <button>{item.buttonText}</button>
              </div>
            </li>
          ))}
        </ul>
        {yourpoints.length > 6 && (
          <div className="viewAllBtn">
            {pointsData ? (
              <button onClick={() => navigate("/login")}>
                VIEW ALL REWARDS
              </button>
            ) : (
              <button onClick={() => setPointsData(6)}>SEE LESS REWARDS</button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default YourRewards;
