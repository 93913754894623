import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import WarnFooter from "../components/WarnFooter";

const Success = () => {
  return (
    <div className="Formbg">
      <div className="signUpTopBox"><div className="signUpTopBoxTitle"><h4>Rewards Program For Red Letter Dealers</h4></div></div>
      <Container>
        <div className="formBox text-center">
          <h3>SUCCESS</h3>
          <p className="mb-5 text-white">Your password has been reset successfully. Please login to access your account.</p>
          <Link to="/login" className="signUpBtn themeBtn">
            LOGIN
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default Success;
