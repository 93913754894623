import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import yourpoints from "../utils/YourPoints.json";
import createAccount from "../Assets/Svg/Create an account.svg";
import shareOnFacebook from "../Assets/Svg/Share on Facebook.svg"
import followOnInstagram from "../Assets/Svg/Follow on inSTAGRAM.svg"
import receipt from "../Assets/Svg/Submit a Receipt.svg"
import review from "../Assets/Svg/Write a Review.svg"
import uploadPhoto from "../Assets/Svg/Upload a Photo.svg"

const YourPoints = () => {
  const navigate = useNavigate();
  const [pointsData, setPointsData] = useState(6);

  return (
    <div id="rewardsSection" className="yourPoints">
          <section className="get_point">
      <div className="getpoint-inner">
        <div className="container">
          <div className="get_tital mail_titaltext_midd">
            <h3>Easy ways to earn points.</h3>
          </div>
          <div className="cardlist-inner">
            <div className="row g-4 justify-content-center">
              <div className="col-lg-4 col-md-6 col-6">
                <div
                  className="acc_crepoi cropfirst_activ wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".5s"
                >
                  <div className="crepoi_text text-center">
                    <div className="crepoi_icon">
                      <img
                        src={createAccount}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h2>Create An Account</h2>
                    <p>Earn 100 points when you enroll into loyalty program.</p>
                    <button className="cre_points">100 POINTS</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6">
                <div
                  className="acc_crepoi creop_activ wow fadeInUp"
                  data-wow-duration="1.2s"
                  data-wow-delay=".7s"
                >
                  <div className="crepoi_text text-center">
                    <div className="crepoi_icon">
                      <img
                        src={shareOnFacebook}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h2>Share On Facebook</h2>
                    <p>Earn 100 points for sharing our page or posts on Facebook.</p>
                    <button className="cre_points">100 POINTS</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6">
                <div
                  className="acc_crepoi cropfirst_activ wow fadeInUp"
                  data-wow-duration="1.2s"
                  data-wow-delay=".7s"
                >
                  <div className="crepoi_text text-center">
                    <div className="crepoi_icon">
                      <img src={followOnInstagram} alt="" className="img-fluid" />
                    </div>
                    <h2>Follow On Instagram</h2>
                    <p>
                      Earn 100 points for sharing our profile or posts on Instagram.
                    </p>
                    <button className="cre_points">100 POINTS</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6">
                <div
                  className="acc_crepoi cropfirst_activ wow fadeInUp"
                  data-wow-duration="1.4s"
                  data-wow-delay=".9s"
                >
                  <div className="crepoi_text text-center">
                    <div className="crepoi_icon">
                      <img
                        src={receipt}
                        alt="Scanicon"
                        className="img-fluid"
                      />
                    </div>
                    <h2>Submit A Receipt</h2>
                    <p>
                    Sumbit your purchase receipts and earn 1 point per dollar spent.
                    </p>
                    <button className="cre_points">1 POINT PER $</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6">
                <div
                  className="acc_crepoi creop_activ wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".5s"
                >
                  <div className="crepoi_text text-center">
                    <div className="crepoi_icon">
                      <img
                        src={uploadPhoto}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h2>Upload A Photo*</h2>
                    <p>
                      Upload a photo of our products and earn 200 points.
                    </p>
                    <button className="cre_points">200 POINTS</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pleaseNote">
          <b>*Please Note: You can earn additional points for the photo upload activity by uploading pictures for:</b><br/> Posting events on social media <span className="pleaseNoteOr">| </span> Vehicle Build Photos <span className="pleaseNoteOr">|</span> Event Posts on Social Media <span className="pleaseNoteOr">|</span> Posting In-Store Promos on Social Media <span className="pleaseNoteOr">|</span> Creative Social Media Posts Promoting the Warn Brands
          </div>
          <div className="viewAllbtnIndependent">
              <button onClick={() => navigate("/login")}>
                VIEW ALL
              </button>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default YourPoints;
