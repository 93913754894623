import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Brand from "../Assets/Images/Warn Header.png";

const index = () => {
  return (
    <div className="headerWrap">
      <Container>
      <a href="/" className="brandSec">
      <img src={Brand} alt="Brand" />
      </a>
      </Container>
    </div>
  );
};

export default index;
