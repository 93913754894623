import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import WarnFooter from '../components/WarnFooter'

function Login() {
  return (
    <div className="Formbg">
      <div className="signUpTopBox">
        <div className="signUpTopBoxTitle">
          <h4>Rewards Program For Red Letter Dealers</h4>
        </div>
      </div>
      <Container>
        <div className="formBox">
          <h3>LOGIN</h3>
          <LoginForm />
          <p>
            <Link to="/reset-password" className="text-white">
              Reset Password
            </Link>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default Login;
